<template>
  <div class="center">
    <v-card>
      <v-card-title>
        Mode beta
      </v-card-title>
      <v-card-text v-if="profile"
        class="center"
      >
        <v-switch v-model="profile.beta"
          @click="onBetaClick"
          :label="profile.beta ? 'Oui' : 'Non'"
          hide-details class="mt-0 pt-0"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import logger from '@/assets/functions/logger'

export default {
  props: ['profile'],
  methods: {
    onBetaClick() {
      if (this.profile.beta === true) {
        Swal.fire({
          text: `Nous sommes actuellement en train de tester de nouvelles fonctionnalités pour notre application et nous avons créé un mode "beta" pour permettre aux utilisateurs de les essayer avant leur lancement officiel. Cependant, nous tenons à souligner que le mode "beta" peut être instable et qu'il est possible que vous rencontriez des bugs ou des erreurs pendant son utilisation. Si vous préférez attendre que tout soit parfaitement fonctionnel, nous vous recommandons de ne pas activer ce mode. Voulez vous continer ?`,
          icon: 'warning',
          confirmButtonText: 'Oui',
          confirmButtonColor: 'var(--v-swalWarning-base)',
          showCancelButton: true,
          cancelButtonText: 'Annuler',
        }).then(result => {
          if (result.isConfirmed) {
            this.profile.save()
            logger.log(this.profile.id, 'BETA', this.profile.email + ' a activé le mode beta')
            this.$router.push('home')
          } else {
            this.profile.beta = false
            this.$router.push('home')
          }
        })
      } else {
        this.profile.save()
        logger.log(this.profile.id, 'BETA', this.profile.email + ' a désactivé le mode beta')
        this.$router.push('home')
      }
    }
  }
}
</script>

<style scoped>
.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>